import "./App.css";
import JoinStar from "./views/screens/JoinStar";
import { Route, Routes } from "react-router-dom";
import RedirectToWordpress from "./views/screens/RedirectToWordpress";
import DeleteUser from "./views/screens/DeleteUser";

const reload = () => window.location.reload();

console.log("Launching join frontend server", process.env.NODE_ENV);

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<RedirectToWordpress />} />
        <Route path="/:username" element={<JoinStar />} />
        <Route path="/apple-app-site-association" onEnter={reload} />
        <Route path="/account/delete" element={<DeleteUser />} />
      </Routes>
    </div>
  );
}

export default App;
