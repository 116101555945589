import React, { useEffect } from "react";

function RedirectToWordpress(props) {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.location.href = "https://starkix.com";
    }
  }, []);
  return null;
}

export default RedirectToWordpress;
