import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createZendeskTicket } from "../../../services/zendesk";

import "./Form.css";

const schema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

const initialValues = {
  email: "",
};

const DeleteUser = () => {
  const [email, setEmail] = useState("");
  const [submissionComplete, setSubmissionComplete] = useState("")
  const [submissionError, setSubmissionError] = useState(null)

  useEffect(() => {
    const createTicket = async () => {
      try {
        await createZendeskTicket(
          `Delete request for user email: ${email}`,
          email
        );
        setSubmissionComplete(true);
      } catch (e) {
        setSubmissionError(e);
      }
    }
    if (email) createTicket();
  }, [email])

  if (submissionComplete) { 
    return <div> 
      We have received your request and will process it promptly.
    </div>
  }

  if (submissionError) { 
    return <div> 
      We had an error processing your request. Please send an email to support@starkix.com and we will resolve your issue promptly.
    </div>
  }
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        setEmail(values.email);
      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty } = formik;
        return (
          <div className="container">
            <h1>Please input the email address you used to register to StarKix</h1>
            <Form>
              <div className="form-row">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  className={
                    errors.email && touched.email ? "input-error" : null
                  }
                />
                <ErrorMessage name="email" component="span" className="error" />
              </div>
              <button
                type="submit"
                className={!(dirty && isValid) ? "disabled-btn" : ""}
                disabled={!(dirty && isValid)}
              >
              Delete My StarKix Account
              </button>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default DeleteUser;
