import axios from "axios";
import { Buffer } from "buffer";

export async function createZendeskTicket(message, email) {
  try {
  const { REACT_APP_ZENDESK_EMAIL, REACT_APP_ZENDESK_TOKEN } = process.env;
    console.log(process.env)
  const ticketData = {
    request: {
      comment: {
        body: message
      },
      requester: { name: email}
    }
  };
  const auth = Buffer
    .from(`${REACT_APP_ZENDESK_EMAIL}/token:${REACT_APP_ZENDESK_TOKEN}`)
    .toString('base64');
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${auth}`
    }
  };
    const zendeskTicket = await axios.post(`https://starkixhelp.zendesk.com/api/v2/requests.json`, ticketData, axiosConfig);
    return zendeskTicket
  } catch (error) {
    console.log('error creating zendesk ticket: ', error);
    throw error;
  }
};

