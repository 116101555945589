import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function JoinStar() {
  const params = useParams();
  useEffect(() => {
    const username = params?.username;
    console.log('username ', username)
    if (process.env.NODE_ENV === "production") {
      window.location.href = `https://starkix.app.link/star?username=${username}`;
    }
  }, [params]);
}

export default JoinStar;
